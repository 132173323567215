/*! 
 *! Menu styling
 */

header { position: relative }
header .menu { top: 100% }

body.fixed { border-top: var(--fixed-header-height) solid }
body.fixed header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: var(--fixed-header-height);
}

.menu li,
.menu ul,
.menu.menu {
    margin: 0;
    padding: 0;
    color: inherit;
    background: inherit;
}

.menu li:hover > ul {
    display: inline;
    margin-left: -1.5em;
}

.menu li a,
.menu li a:hover {
    color: inherit;
    text-decoration: none;
}

.menu li ul { display: none }

.menu li li { float: none }

.menu li li a {
    float: none;
    color: inherit;
    display: block;
    padding: 1em;
}

.menu li,
.menu li a { display: inline-block }

.menu > li {
    margin: 0 1em;
    list-style: none;
}

.menu .dropdown {
    background: inherit;
    position: relative;
}

.menu .dropdown ul {
    white-space: normal;
    position: absolute;
    top: 100%;
    left: 0;
    background: inherit;
    border-bottom: 3px solid;
    padding: 0;
}

.menu .dropdown li { white-space: nowrap }

.menu .dropdown li a { min-width: 200px }

#menu-icon,
.menu-icon-wrapper { display: none }

#menu-icon span {
    border: 2px solid;
    width: 30px;
    margin-bottom: 5px;
    display: block;
    transition: all .1s;
}

.logo,
.logo a,
.logo a:hover {
    color: inherit;
    text-decoration: none;
}


.menu .sub-menu-items{
    position: relative;
}

.menu .sub-menu-items li a{
    margin: 0 1em;
}

.menu .sub-menu-items .sub-menu-items {
    position: absolute;
    transform: none;
    left: 100%;
    top: 0;
    margin: 0;
}

@media (max-width: var(--breakpoint-menu)) { 
    .menu,
    .menu.show { transition: max-height .5s linear }

    .menu .dropdown ul {
        position: relative;
        margin: 0 1em;
        padding: 0;
        border-bottom: 0;
        border-left: 3px solid;
    }

    .menu li,
    nav,
    ul.menu { float: none }

    .menu li li,
    .menu>li {
        margin: 0;
        padding: 0;
    }

    .menu li a,
    .menu li li a {
        margin: 0 10px;
        padding: 10px;
    }

    .menu li,
    .menu li ul,
    .menu li:hover ul {
        display: block;
        margin: 0 1em;
    }

    .menu,
    .menu a,
    .menu li { width: 100% }

    .menu-icon-wrapper { display: flex }

    .menu { margin: 0 }

    .menu.show {
        overflow-y: auto;
        max-height: 100vh;
    }

    .menu {
        display: block;
        max-height: 0;
        padding: 10px;
        position: absolute;
        overflow: hidden;
        left: 0;
        right: 0;
        background: inherit;
        z-index: 1000;
    }

    #menu-icon {
        display: inline-block;
        cursor: pointer;
        align-self: center;
        flex-grow: 0;
        padding: 10px;
        z-index: 4;
    }
    #menu-icon.active .first {
        transform: rotate(45deg);
        margin-top: 10px;
    }

    #menu-icon.active .second {
        transform: rotate(135deg);
        position: relative;
        top: -9px;
    }

    #menu-icon .third { margin-bottom: 0 }
    #menu-icon.active .third { display: none }

}